import { Link, useParams } from "@remix-run/react";
import { Button, Flex } from "sparta";
import useResumeablePath from "~/utils/hooks/useResumeablePath";

interface IAuthButtons {
  show: boolean;
}

export default function AuthButtons({ show }: IAuthButtons) {
  const path = useResumeablePath();
  const params = useParams();
  const route = `/access/login?resume=${path}${params?.platform ? `&platform=${params.platform}` : ""}`;

  return show ? (
    <Flex justify="end" align="center">
      <Link to={route}>
        <Button style={{ width: 98 }} tabIndex={-1} variant="surface">
          Log in
        </Button>
      </Link>
      <Link to="https://g2xchange.com/pricing">
        <Button style={{ width: 98 }}>Sign up</Button>
      </Link>
    </Flex>
  ) : null;
}
